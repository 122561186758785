<template>
    <custom-modal
        width="90%"
        title="查看申请资料"
        :footer="null"
        :visible="visible"
        @on-close="() => $emit('on-close')"
    >
        <modal-content :order-no="orderNo" :handling-info-tpl="handlingInfoTpl" />
    </custom-modal>
</template>
<script>
import CustomModal from '@components/CustomModal'
import ModalContent from './Content'

export default {
    name: 'LookOverApplyInfoModal',
    components: {
        CustomModal,
        ModalContent
    },
    props: {
        visible: Boolean,
        orderNo: String,
        handlingInfoTpl: Object
    }
}
</script>
