import axios from '@service'
import { GET_INSTITUTION_INFO } from './api'
import { HTTP_ERR_SUCCESS } from '@constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@constant/error'

// 获取（办理）机构信息
export default async function getInstitutionInfoService(payload = {}, config = {}) {
    const param = {
        ...GET_INSTITUTION_INFO,
        ...config,
        params: payload
    }

    const { data } = await axios(param)

    if (data.code === HTTP_ERR_SUCCESS) {
        return data.result || {}
    } else {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
