<template>
    <div class="margin-payment-detail">
        <!-- 项目信息 -->
        <project-info-panel :workflow-apply-record-id="workflowApplyRecordId" />

        <!-- 办理机构 -->
        <information-panel
            class="has-margin-top"
            :columns="columns"
            :loading="loading"
            :data-source="institutionInfo"
        >
            <template #title>
                <span style="font-size: 14px">办理机构</span>
            </template>
        </information-panel>

        <!-- 办理资料 -->
        <a-card class="has-margin-top">
            <template #title>
                <span style="font-size: 14px">办理资料</span>
            </template>
            <file-upload-description-list v-bind="fileUploadListProps" />
        </a-card>
    </div>
</template>
<script>
import { Card as ACard } from 'ant-design-vue'
import InformationPanel from '@components/InformationPanel'
import FileUploadDescriptionList from '@weights/FileUploadDescriptionList'
import ProjectInfoPanel from '@weights/marginManagement/MarginPaymentDetail/ProjectInfoPanel'

import { empty as emptyFilter } from '@utils'

import getInstitutionInfoService from '@service/marginManagement/marginPaymentDetail/getInstitutionInfoService'

const columns = Object.freeze([
    {
        dataIndex: 'companyName',
        label: '办理银行',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'contactName',
        label: '机构联系人',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'contactPhone',
        label: '机构联系人电话',
        customRender: val => emptyFilter(val)
    }
])

export default {
    name: 'ModalContent',
    components: {
        ACard,
        InformationPanel,
        ProjectInfoPanel,
        FileUploadDescriptionList
    },
    props: {
        orderNo: String,
        handlingInfoTpl: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            columns,
            loading: false,
            institutionInfo: {}
        }
    },
    computed: {
        workflowApplyRecordId() {
            return this.$route.query.workflowApplyRecordId
        },
        fileUploadListProps() {
            return Object.freeze({
                colon: 0,
                align: 1,
                ...this.handlingInfoTpl.props || {}
            })
        }
    },
    watch: {
        orderNo: {
            handler(orderNo) {
                this.getInstitutionInfo(orderNo)
            },
            immediate: true
        }
    },
    methods: {
        async getInstitutionInfo(orderNo) {
            try {
                this.loading = true

                // eslint-disable-next-line
                const info = await getInstitutionInfoService({ orderNo })

                this.institutionInfo = info || {}
            } catch (e) {
                this.$message.warning(e.message)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
